

.payment-method-micro-frontend {
    display: grid;
    grid-template-rows: 70px calc(100vh - 70px);
}

.verint-search-container {
    display: grid;
    grid-template-rows: 80px calc(100vw - 480px);
    padding: 5px;
    background-color: white;
}

.filter-container {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.button-filter-container {
    align-items: center;
    justify-content: center;
    display: flex;
}

.filter-container .form-field{
    padding-bottom: 10px;
}

.verint-search-result {
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    overflow-y: auto;
}

.call-recording-card {
    border: #F47B20 2px solid;
    background-color: #fefefe;
    border-radius: 5px;
    margin: 5px;
    display: grid;
    grid-template-columns: 50px auto 50px;
    width: 50%;
}

.call-recording-card-icon,  .call-recording-card-check{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: #F47B20 2px solid;
    background-color: #F47B20;
}

.call-recording-card-check {
    background-color: white;
    border: none;
}

.call-recording-card-info {
    padding-left: 5px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30px 30px;
}
.verint-search-result-bar {
    display: flex;
    justify-content: space-between;
}

.verint-search-result-bar button {
    margin-left: 10px;
}

.verint-search-result-container {
    padding-top: 5px;
    height: calc(100vh - 180px);
}

.main-container {
    background-color: #f0f0f0;
    padding: 20px 75px;
}

.spin-icon svg.tag-icon {
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }